import axios from "axios";
import ApiError from "./api-error";

// export default instance
let Axios = axios.create({
    // withCredentials: false,
    crossDomain: true,
    crossOrigin: true,
});

Axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token") || null;
    config.headers.Authorization = token ? `Bearer ` + token : null;
    return config;
});

Axios.interceptors.response.use(
    (response) => {
        // TO STORE THE JWT TOKEN FROM RESPONSE
        return response;
    },
    (error) => {
        console.error("API Error:", error); // Log the error for easier debugging
        return ApiError.errorHandler(error);
    }
);

export default Axios;
