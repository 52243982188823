// export const BASE_URL = import.meta.env.REACT_APP_API_URL || 'http://localhost:8080/api/v1';
export const BASE_URL = 'http://localhost:8080/api/v1';

const ROSTER = "/roster";
const REMOTE = "/remote";
const EMERGENCY = "/emergency";
const BELL = "/bell";
const COMPANY_BASE = "/company";
const PERSONAL_RECORD_BASE = "/personalRecord";
const COMPANY_RECORD_BASE = "/companyRecord";
const USER_BASE = "/user";
const PLAY = "/play";

const FILE_BASE = "/file";
const FILES = "/files";
const TYPE = "/type";
const LOGIN = "/login";
const DETAIL = "/detail";
const UPLOAD = "/upload";
const UPLOADS = "/uploads";
const SEARCH = "/search";
const RECONCILE = "/reconcile";
const ACTIVE = "/active";
const MIN = "/min";

export const fileApiConstants = {
    UPLOAD_FILE: BASE_URL.concat(FILE_BASE.concat(UPLOAD)),
    UPLOAD_FILES: BASE_URL.concat(FILE_BASE.concat(UPLOADS)),
    DELETE_FILE: BASE_URL.concat(FILE_BASE),
};

export const rosterConstants = {
    ROSTER_SEARCH: BASE_URL.concat(ROSTER).concat("/search"),
};

export const bellRemoteConstants = {
    BELL_SWITCH: BASE_URL.concat(REMOTE),
};

export const emergencyConstants = {
    PLAY_EMERGENCY_BELL: BASE_URL.concat(EMERGENCY.concat(PLAY)),
};

export const bellConstants = {
    BELL_SAVE: BASE_URL.concat(BELL),
    BELL_SEARCH: BASE_URL.concat(BELL.concat(SEARCH)),
};


export const userApiConstants = {
    SIGN_UP: BASE_URL.concat("/user/signup"),
    VERIFY_OTP: BASE_URL.concat("/user/verifyOtp"),
    RESEND_OTP: BASE_URL.concat("/user/resendOtp"),
    SEARCH_USERS: BASE_URL.concat(USER_BASE.concat(SEARCH))
};

export const loginApiConstants = {
    LOGIN_USER: BASE_URL.concat(LOGIN),
};
