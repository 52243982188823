// Chakra imports
import {Box, Flex, Grid, useColorModeValue,} from "@chakra-ui/react";
// assets
// Custom icons
import React, {useState} from "react";
import Card from "../../../components/Card/Card";
import DigitalBellRemote from "../../../components/Bell/Remote";
import ControlTab from "./components/ControlTab";

export default function Dashboard() {
    const iconBoxInside = useColorModeValue("white", "white");

    const [isLicenceExpiring, setIsLicenceExpiring] = useState(true);
    const [licenceType, setLicenceType] = useState("TRAIL");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    return (
        <Flex flexDirection='column' pt={{base: "120px", md: "75px"}} overflow="hidden">
            {/*<SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>*/}
            {/*  <MiniStatistics*/}
            {/*    title={"Today's Moneys"}*/}
            {/*    amount={"$53,000"}*/}
            {/*    percentage={55}*/}
            {/*    icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}*/}
            {/*  />*/}
            {/*  <MiniStatistics*/}
            {/*    title={"Today's Users"}*/}
            {/*    amount={"2,300"}*/}
            {/*    percentage={5}*/}
            {/*    icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}*/}
            {/*  />*/}
            {/*  <MiniStatistics*/}
            {/*    title={"New Clients"}*/}
            {/*    amount={"+3,020"}*/}
            {/*    percentage={-14}*/}
            {/*    icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}*/}
            {/*  />*/}
            {/*  <MiniStatistics*/}
            {/*    title={"Total Sales"}*/}
            {/*    amount={"$173,000"}*/}
            {/*    percentage={8}*/}
            {/*    icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}*/}
            {/*  />*/}
            {/*</SimpleGrid>*/}

            {isLicenceExpiring ?
                <div>
                    <Box bg="red" w="100%" p="4" color="white">
                        {licenceType === "TRAIL" ? "तपाईं सफ्टवेयरको परीक्षण चरणमा हुनुहुन्छ।" : "तपाईंको सफ्टवेयर लाइसेन्स चाँडै समाप्त हुँदैछ, कृपया समयमा नवीकरण गर्नुहोस्।"}
                    </Box>
                </div> : ""
            }
            <Grid
                templateColumns={{md: "1fr", lg: "1.8fr 1.2fr"}}
                templateRows={{md: "1fr auto", lg: "1fr"}}
                my='26px'
                gap='24px'>
                {/*<WorkWithTheRockets*/}
                {/*    backgroundImage={peopleImage}*/}
                {/*    title={"डिजिटल घण्टीमा स्वागत छ!"}*/}
                {/*    description={*/}
                {/*        "शिक्षा प्रणाली र प्रक्रियालाई आधुनिक बनाउने डिजिटल घण्टी"*/}
                {/*    }*/}
                {/*/>*/}

                <ControlTab/>

                <Card minHeight='290.5px' p='1.2rem'>

                    {userInfo && userInfo?.isRemoteOn === 'Y' ? <DigitalBellRemote/> : ""}

                    {/*<BellPlayer*/}
                    {/*    bellSwitch={bellSwitch}/>*/}
                    {/*<>*/}
                    {/*    बेलको स्थिति: <Switch*/}
                    {/*    size={"lg"}*/}
                    {/*    colorPalette={"yellow"}*/}
                    {/*    onChange={(event) => handleBellSwitchChange(event)}*/}
                    {/*    />*/}
                    {/*</>*/}
                </Card>
            </Grid>

            {/*<BuiltByDevelopers*/}
            {/*    title={"Built by Developers"}*/}
            {/*    name={"Purity UI Dashboard"}*/}
            {/*    description={*/}
            {/*        "From colors, cards, typography to complex elements, you will find the full documentation."*/}
            {/*    }*/}
            {/*    image={*/}
            {/*        <Image*/}
            {/*            src={logoChakra}*/}
            {/*            alt='chakra image'*/}
            {/*            minWidth={{ md: "300px", lg: "auto" }}*/}
            {/*        />*/}
            {/*    }*/}
            {/*/>*/}


            {/*<Grid*/}
            {/*    templateColumns={{sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr"}}*/}
            {/*    templateRows={{sm: "1fr auto", md: "1fr", lg: "1fr"}}*/}
            {/*    gap='24px'>*/}

            {/*    <Projects*/}
            {/*        title={"घण्टीहरू"}*/}
            {/*        amount={30}*/}
            {/*        captions={["Companies", "Members", "Budget", "Completion"]}*/}
            {/*        data={dashboardTableData || []}*/}
            {/*    />*/}
            {/*    <OrdersOverview*/}
            {/*        title={"Orders Overview"}*/}
            {/*        amount={30}*/}
            {/*        data={timelineData || []}*/}
            {/*    />*/}
            {/*</Grid>*/}
        </Flex>
    );
}
