import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
// Chakra imports
import {Button, Flex, Grid, Icon, Text, useColorModeValue,} from "@chakra-ui/react";
import CardBody from "../../../components/Card/CardBody";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import rosterBanner from "assets/img/roster-banner.png";
import {FaPlus} from "react-icons/fa";
import React from "react";
import RosterCard from "./RosterCard";


export const RosterTable = ({title, description, rosterList,setIsRosterPage}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card p='16px' my='24px'>
            <CardHeader p='12px 5px' mb='12px'>
                <Flex direction='column'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold'>
                        {title}
                    </Text>
                    <Text fontSize='sm' color='gray.500' fontWeight='400'>
                        {/*{description}*/}
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody px='5px'>
                <Grid
                    templateColumns={{sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)"}}
                    templateRows={{sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr"}}
                    gap='24px'>

                    {rosterList && rosterList.map((roster, index) => {
                        return (
                            <RosterCard
                                image={rosterBanner}
                               roster={roster}
                                category={"Modern"}
                                description={
                                    <>
                                        As Uber works through a huge amount of internal management turmoil
                                        TotalBells: {roster?.bellNumber}
                                    </>
                                }
                                avatars={[avatar2, avatar4, avatar6]}
                                setIsRosterPage={setIsRosterPage}
                            />
                        )
                    })}
                    <Button
                        p='0px'
                        bg='transparent'
                        color='gray.500'
                        border='1px solid lightgray'
                        borderRadius='15px'
                        minHeight={{sm: "200px", md: "100%"}}>
                        <Flex direction='column' justifyContent='center' align='center'>
                            <Icon as={FaPlus} fontSize='lg' mb='12px'/>
                            <Text fontSize='lg' fontWeight='bold'>
                                Create a New Project
                            </Text>
                        </Flex>
                    </Button>
                </Grid>
            </CardBody>
        </Card>
    );
}