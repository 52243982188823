import {bellRemoteConstants, emergencyConstants} from "../../constants/ApiConstant";
import Axios from "../../axios.js";

export async function toggleBellSwitch(params) {
    return Axios.post(bellRemoteConstants.BELL_SWITCH, params);
}

export async function playEmergencyBell(params) {
    return Axios.post(emergencyConstants.PLAY_EMERGENCY_BELL, params);
}
