// Chakra Imports
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Flex,
    Link,
    Switch,
    Text,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
import GitHubButton from "react-github-btn";
import {Separator} from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {FaFacebook} from "react-icons/fa";

export default function Configurator(props) {
    const {secondary, isOpen, onClose, fixed, ...rest} = props;
    const [switched, setSwitched] = useState(props.isChecked);

    const {colorMode, toggleColorMode} = useColorMode();
    // Chakra Color Mode
    let fixedDisplay = "flex";
    if (props.secondary) {
        fixedDisplay = "none";
    }

    let bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "white"
    );
    let colorButton = useColorModeValue("white", "gray.700");
    const secondaryButtonBg = useColorModeValue("white", "transparent");
    const secondaryButtonBorder = useColorModeValue("gray.700", "white");
    const secondaryButtonColor = useColorModeValue("gray.700", "white");
    const settingsRef = React.useRef();
    return (
        <>
            <Drawer
                isOpen={props.isOpen}
                onClose={props.onClose}
                placement={document.documentElement.dir === "rtl" ? "left" : "right"}
                finalFocusRef={settingsRef}
                blockScrollOnMount={false}
            >
                <DrawerContent>
                    <DrawerHeader pt="24px" px="24px">
                        <DrawerCloseButton/>
                        <Text fontSize="xl" fontWeight="bold" mt="16px">
                            Digital Ghanti Configurator
                        </Text>
                        <Text fontSize="md" mb="16px">
                            See your dashboard options.
                        </Text>
                        <Separator/>
                    </DrawerHeader>
                    <DrawerBody w="340px" ps="24px" pe="40px">
                        <Flex flexDirection="column">
                            <Box>
                                <Text fontSize="md" fontWeight="600">
                                    Sidenav Type
                                </Text>
                                <Text fontSize="sm" mb="16px">
                                    Choose between 2 different sidenav types.
                                </Text>
                                <Flex>
                                    <Button
                                        w="50%"
                                        p="8px 32px"
                                        me="8px"
                                        colorScheme="teal"
                                        borderColor="teal.300"
                                        color="teal.300"
                                        variant="outline"
                                        fontSize="xs"
                                        onClick={props.onTransparent}
                                    >
                                        Transparent
                                    </Button>
                                    <Button
                                        type="submit"
                                        bg="teal.300"
                                        w="50%"
                                        p="8px 32px"
                                        mb={5}
                                        _hover="teal.300"
                                        color="white"
                                        fontSize="xs"
                                        onClick={props.onOpaque}
                                    >
                                        Opaque
                                    </Button>
                                </Flex>
                            </Box>
                            <Box
                                display={fixedDisplay}
                                justifyContent="space-between "
                                mb="16px"
                            >
                                <Text fontSize="md" fontWeight="600" mb="4px">
                                    Navbar Fixed
                                </Text>
                                <Switch
                                    colorScheme="teal"
                                    isChecked={switched}
                                    onChange={(event) => {
                                        if (switched === true) {
                                            props.onSwitch(false);
                                            setSwitched(false);
                                        } else {
                                            props.onSwitch(true);
                                            setSwitched(true);
                                        }
                                    }}
                                />
                            </Box>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mb="24px"
                            >
                                <Text fontSize="md" fontWeight="600" mb="4px">
                                    Dark/Light
                                </Text>
                                <Button onClick={toggleColorMode}>
                                    Toggle {colorMode === "light" ? "Dark" : "Light"}
                                </Button>
                            </Flex>

                            <Separator/>
                            <Box mt="24px">
                                <Text fontSize="md" fontWeight="600">
                                    Sidenav Type
                                </Text>
                                <Text fontSize="sm" mb="16px">
                                    Choose between 2 different sidenav types.
                                </Text>
                                <Box>
                                    {/*<Link*/}
                                    {/*    href="https://www.creative-tim.com/product/purity-ui-dashboard"*/}
                                    {/*    w="100%"*/}
                                    {/*    mb="16px"*/}
                                    {/*>*/}
                                    {/*    <Button*/}
                                    {/*        w="100%"*/}
                                    {/*        mb="16px"*/}
                                    {/*        bg={bgButton}*/}
                                    {/*        color={colorButton}*/}
                                    {/*        fontSize="xs"*/}
                                    {/*        variant="no-hover"*/}
                                    {/*        px="30px"*/}
                                    {/*    >*/}
                                    {/*        Free Download*/}
                                    {/*    </Button>*/}
                                    {/*</Link>*/}
                                    <Link
                                        href="https://digitalghanti.com/"
                                        w="100%"
                                    >
                                        <Button
                                            w="100%"
                                            bg={secondaryButtonBg}
                                            border="1px solid"
                                            borderColor={secondaryButtonBorder}
                                            color={secondaryButtonColor}
                                            fontSize="xs"
                                            variant="no-hover"
                                            px="20px"
                                            mb="16px"
                                        >
                                            <Text textDecorationColor="none">Documentation</Text>
                                        </Button>
                                    </Link>
                                </Box>
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    w="100%"
                                    mb="16px"
                                >
                                    <GitHubButton
                                        href="https://github.com/creativetimofficial/purity-ui-dashboard"
                                        data-icon="octicon-star"
                                        data-show-count="true"
                                        aria-label="Star creativetimofficial/purity-ui-dashboard on GitHub"
                                    >
                                        Star
                                    </GitHubButton>
                                </Flex>
                                <Box w="100%">
                                    <Text mb="6px" textAlign="center">
                                        Thank you for using Digital Ghanti- Automated Belling Software!
                                    </Text>
                                    <Flex justifyContent="center" alignContent="center">
                                        <Link
                                            isExternal="true"
                                            href="https://www.facebook.com/sharer/sharer.php?u=https://digitalghanti.com/"
                                        >
                                            <Button colorScheme="facebook" leftIcon={<FaFacebook/>}>
                                                <Text>Share</Text>
                                            </Button>
                                        </Link>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}
Configurator.propTypes = {
    secondary: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    fixed: PropTypes.bool,
};
