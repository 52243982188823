/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import SignIn from "./views/Auth/SignIn";
import Logout from "./views/Auth/Logout/Logout";

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem("userInfo") ? (
                <Component {...props} />
            ) : (
                <Redirect from={"/"} to="/signin"/>
            )
        }
    />
);

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Route path="/signout" component={Logout}/>
            <Route path="/signin" component={SignIn}/>
            <PrivateRoute path="/auth" component={AuthLayout}/>
            <PrivateRoute path="/admin" component={AdminLayout}/>
            <PrivateRoute path="/rtl" component={RTLLayout}/>
            <Redirect from="/" to="/admin/dashboard"/>
        </Switch>
    </HashRouter>,
    document.getElementById("root")
);
