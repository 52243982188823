import React, {useEffect, useState} from 'react';
import {Stomp} from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const SchedulerComponent = () => {
    const [payload, setPayload] = useState({});

    useEffect(() => {
        const socket = new SockJS('http://localhost:8080/ws', null, {
            // Options are passed here; however, note that these are not standard SockJS properties
            // These properties are commonly for XMLHttpRequest or Fetch
            withCredentials: false,
            crossDomain: true,
            crossOrigin: true,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        });


        const stompClient = Stomp.over(socket);

        stompClient.connect({}, (frame) => {
            console.log('Connected: ' + frame);
            stompClient.subscribe('/topic/scheduler', (payload) => {
                console.log('payload =>', payload);
                setPayload((prevPayload) => JSON.parse(payload.body));
            });
        });

        return () => {
            stompClient.disconnect();
        };
    }, []);

    return payload;

    // return (
    //     <div>
    //         <h2>Scheduler Messages</h2>
    //         <ul>
    //             {messages.map((msg, index) => (
    //                 <li key={index}>{msg}</li>
    //             ))}
    //         </ul>
    //     </div>
    // );
};

export default SchedulerComponent;