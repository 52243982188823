// Chakra imports
import {
    Button,
    Flex,
    HStack,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BelllTableRow from "../../../../components/Tables/BelllTableRow";
import React from "react";
import AddBellModal from "./AddBellModal";

const BellTable = ({title, captions, bellList, handleBellUpload}) => {
    const textColor = useColorModeValue("gray.700", "white");

    const {isOpen, onOpen, onClose} = useDisclosure(); // Modal state management

    return (
        <Card overflowX={{sm: "scroll", xl: "hidden"}}>
            <CardHeader p="6px 0px 22px 0px">
                {/* Flex container for header elements */}
                <Flex justify="space-between" align="center">
                    {/* Title on the left */}
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                        {title}
                    </Text>

                    {/* Buttons on the right */}
                    <HStack spacing="4">
                        <Button colorScheme="blue" onClick={onOpen}>
                            Add Bell
                        </Button>
                        <Button colorScheme="teal">Update Bell</Button>
                    </HStack>
                </Flex>
            </CardHeader>

            <CardBody>
                <Table variant='simple' color={textColor}>
                    <Thead>
                        <Tr my='.8rem' pl='0px' color='gray.400'>
                            {captions.map((caption, idx) => {
                                return (
                                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                                        {caption}
                                    </Th>
                                );
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        <>
                            {isOpen ?
                                <AddBellModal
                                    isOpen={isOpen}
                                    onClose={onClose}
                                    handleBellUpload={handleBellUpload}
                                /> : ""
                            }
                            {bellList && bellList.length > 0 ? bellList.map((row) => {
                                    return (
                                        <BelllTableRow
                                            key={`${row.email}-${row.name}`}
                                            name={row.name}
                                            hourTime={row.hourTime}
                                            bell={row.bell}
                                            queue={row.queue}
                                            status={row.status}
                                            email={row.email}
                                            subdomain={row.subdomain}
                                            domain={row.domain}
                                            date={row.date}
                                        />
                                    );
                                }) :
                                "Bell Not Available"
                            }
                        </>
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default BellTable;
