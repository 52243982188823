import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    ChakraProvider,
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Spinner,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {ArrowForwardIcon, SearchIcon} from "@chakra-ui/icons";
import {searchBells} from "./services";
import useWebSocketContext from "../../../../../socket/SchedulerComponent";
import {isTimeGreater} from "../../../../../utils/TimeUtils";
import TimerSpinner from "./TimerSpinner";

const BellTable = () => {
    const [bells, setBells] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredBells, setFilteredBells] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const payload = useWebSocketContext();
    console.log("RemoteScreen payload from bell server:", payload);

    useEffect(() => {
        fetchBellList();
    }, []);

    useEffect(() => {
        setFilteredBells(
            bells.filter((bell) =>
                bell.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, bells]);

    const handleStopForToday = (id) => {
        setBells((prevBells) =>
            prevBells.map((bell) =>
                bell.id === id ? {...bell, status: "Inactive", queue: 0} : bell
            )
        );
    };

    const handleSkip = (id) => {
        setBells((prevBells) =>
            prevBells.map((bell) =>
                bell.id === id ? {...bell, queue: bell.queue - 1} : bell
            )
        );
    };

    const fetchBellList = async () => {
        try {
            setIsLoading(true);
            const request = {name: ''};
            const response = await searchBells(request);
            if (response) {
                setBells(response.data);
                setFilteredBells(response.data);
            }
        } catch (e) {
            console.log("Error while fetching bells");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ChakraProvider>
            <Box maxW="800px" mx="auto" p={4}>
                <Flex mb={4}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300"/>
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search Bells"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>
                </Flex>

                {isLoading ? (
                    <Flex justifyContent="center" alignItems="center" minH="200px">
                        <Spinner size="xl" color="teal.500"/>
                    </Flex>
                ) : (
                    <TableContainer>
                        <Table variant="simple" colorScheme="teal" size="sm">
                            <Thead>
                                <Tr>
                                    <Th>नाम</Th>
                                    <Th>समय</Th>
                                    <Th>स्थिति</Th>
                                    <Th>पंक्ति</Th>
                                    <Th>सक्रिय/निष्क्रिय</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredBells.map((bell) => (
                                    <Tr key={bell.id}>
                                        <Td>{bell.name}</Td>
                                        <Td>{bell.hourTime}</Td>
                                        <Td backgroundColor={payload.activeBell === bell.hourTime ?
                                            payload.isBellPlaying ? "green" : "red"
                                            : isTimeGreater(payload.activeBell || null, bell.hourTime)
                                                ? "red"
                                                : "yellow"}>

                                            {payload.activeBell === bell.hourTime ?
                                                payload.isBellPlaying ? "Playing" : "बज्यो"
                                                : isTimeGreater(payload.activeBell || null, bell.hourTime)
                                                    ? "बज्यो"
                                                    : "आउन लागेको"}
                                        </Td>
                                        <Td>{bell.queue}</Td>
                                        <Td>
                                            <Flex>
                                                <div>
                                                    {!isTimeGreater(payload.activeBell || null, bell.hourTime) ?
                                                        <Switch colorScheme="green" size="lg"/> :
                                                        <Switch disabled={true} colorScheme="green" size="lg"/>}
                                                </div>

                                                {payload.activeBell === bell.hourTime ?
                                                    <>
                                                        <IconButton
                                                            icon={<ArrowForwardIcon/>}
                                                            aria-label="Skip"
                                                            colorScheme="blue"
                                                            onClick={() => handleSkip(bell.id)}
                                                        />
                                                        <TimerSpinner/>
                                                    </>
                                                    : ""}

                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </ChakraProvider>
    );
};

export default BellTable;