import React, { useEffect, useState } from "react";
import { CircularProgress, CircularProgressLabel, Box, Text } from "@chakra-ui/react";

const TimerSpinner = ({ duration = 10 }) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const startTime = Date.now();

        const interval = setInterval(() => {
            const elapsedTime = (Date.now() - startTime) / 1000; // seconds
            const percentage = Math.max(100 - (elapsedTime / duration) * 100, 0);
            setProgress(percentage);

            if (percentage <= 0) {
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [duration]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress
                value={progress}
                size="40px"
                thickness="8px"
                color="blue.400"
                trackColor="gray.200"
            >
                <CircularProgressLabel>
                    <Text fontSize="lg">{Math.ceil((progress / 100) * duration)}s</Text>
                </CircularProgressLabel>
            </CircularProgress>
            {/*<Text mt={4} fontSize="md" color="gray.600">*/}
            {/*    Timer: {duration}s*/}
            {/*</Text>*/}
        </Box>
    );
};

export default TimerSpinner;