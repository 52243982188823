import {
    Box,
    Button,
    Input,
    Textarea,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Tag,
    useToast,
    Select,
    HStack,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';

function AnnouncementForm() {
    const [text, setText] = useState('');
    const [audioFile, setAudioFile] = useState(null);
    const [recording, setRecording] = useState(false);
    const [scheduledDate, setScheduledDate] = useState('');
    const [announcements, setAnnouncements] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Number of items per page
    const [statusFilter, setStatusFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const toast = useToast();
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);

    const handleRecord = async () => {
        if (recording) {
            mediaRecorderRef.current.stop();
            setRecording(false);
        } else {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                setAudioFile(new File([audioBlob], 'recording.wav', { type: 'audio/wav' }));
                audioChunks.current = [];
                toast({
                    title: 'Recording Complete',
                    description: 'Your announcement recording is ready for scheduling.',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                });
            };

            mediaRecorderRef.current.start();
            setRecording(true);
        }
    };

    const handleSubmit = () => {
        const newAnnouncement = {
            id: announcements.length + 1,
            type: audioFile ? 'Audio' : text ? 'Text' : 'Recording',
            status: 'Scheduled',
            scheduledDate,
        };
        setAnnouncements([...announcements, newAnnouncement]);
        setText('');
        setAudioFile(null);
        setScheduledDate('');

        toast({
            title: 'Announcement Scheduled',
            description: 'Your announcement has been scheduled successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Scheduled':
                return 'blue';
            case 'Completed':
                return 'green';
            case 'Failed':
                return 'red';
            default:
                return 'gray';
        }
    };

    const filteredAnnouncements = announcements
        .filter((announcement) => {
            return (
                (!statusFilter || announcement.status === statusFilter) &&
                (!dateFilter || announcement.scheduledDate.startsWith(dateFilter))
            );
        });

    const totalPages = Math.ceil(filteredAnnouncements.length / itemsPerPage);
    const paginatedAnnouncements = filteredAnnouncements.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <Box>
            <Button onClick={handleRecord} mb={4}>
                {recording ? 'Stop Recording' : 'Record Announcement'}
            </Button>
            <Input
                type="file"
                onChange={(e) => setAudioFile(e.target.files[0])}
                accept="audio/*"
                mb={4}
            />
            <Textarea
                placeholder="Or type your announcement here"
                value={text}
                onChange={(e) => setText(e.target.value)}
                mb={4}
            />
            <Input
                type="datetime-local"
                value={scheduledDate}
                onChange={(e) => setScheduledDate(e.target.value)}
                mb={4}
            />
            <Button onClick={handleSubmit} colorScheme="teal" mb={4}>
                Schedule Announcement
            </Button>

            {/* Filters */}
            <HStack spacing={4} mt={8}>
                <Select
                    placeholder="Filter by Status"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                >
                    <option value="Scheduled">Scheduled</option>
                    <option value="Completed">Completed</option>
                    <option value="Failed">Failed</option>
                </Select>
                <Input
                    type="date"
                    placeholder="Filter by Date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                />
            </HStack>

            {/* Log Table */}
            <Table variant="simple" mt={4}>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Type</Th>
                        <Th>Status</Th>
                        <Th>Scheduled Date & Time</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {paginatedAnnouncements.map((announcement) => (
                        <Tr key={announcement.id}>
                            <Td>{announcement.id}</Td>
                            <Td>{announcement.type}</Td>
                            <Td>
                                <Tag colorScheme={getStatusColor(announcement.status)}>
                                    {announcement.status}
                                </Tag>
                            </Td>
                            <Td>{announcement.scheduledDate}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Pagination Controls */}
            <HStack spacing={4} mt={4} justifyContent="center">
                <Button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <Box>Page {currentPage} of {totalPages}</Box>
                <Button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </HStack>
        </Box>
    );
}

export default AnnouncementForm;