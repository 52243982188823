import React, { useState } from "react";
import {
    Avatar,
    Badge,
    Button,
    ButtonGroup,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Td,
    Text,
    Tr,
    Tooltip,
    useColorModeValue,
    Input,
    FormLabel,
    FormControl,
    Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react";
import logo from "assets/icons/bell.png";
import BellPlayer from "../../views/Dashboard/Roster/Bells/BellPlayer";
import { BASE_URL } from "../../constants/ApiConstant";

function BelllTableRow(props) {
    const { name, hourTime, queue, bell, email, status } = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isChangeTimeOpen, setIsChangeTimeOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleEditOpen = () => setIsEditOpen(true);
    const handleEditClose = () => setIsEditOpen(false);

    const handleChangeTimeOpen = () => setIsChangeTimeOpen(true);
    const handleChangeTimeClose = () => setIsChangeTimeOpen(false);

    const handleDeleteOpen = () => setIsDeleteOpen(true);
    const handleDeleteClose = () => setIsDeleteOpen(false);

    const cellStyle = {
        padding: "0.5px 4px", // Reduce padding
        fontSize: "14px", // Adjust font size
    };

    return (
        <>
            {/* Table Row */}
            <Tr style={{ height: "60px" }}>
                <Td style={cellStyle} pl="0px">
                    <Flex align="center" py="4px" minWidth="20%" flexWrap="nowrap">
                        <Avatar src={logo} w="40px" h="40px" borderRadius="12px" me="8px" />
                        <Flex direction="column">
                            <Text fontSize="sm" color={textColor} fontWeight="bold" minWidth="100%">
                                {name}
                            </Text>
                            <Text fontSize="xs" color="gray.400" fontWeight="normal">
                                {email}
                            </Text>
                        </Flex>
                    </Flex>
                </Td>

                <Td style={cellStyle}>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb="2px">
                        {hourTime}
                    </Text>
                </Td>

                <Td style={cellStyle}>
                    <BellPlayer bell={bell} audioSrc={BASE_URL.concat("")} textColor={"red"} />
                </Td>

                <Td style={cellStyle}>
                    <Text fontSize="sm" color={textColor} fontWeight="bold" pb="2px">
                        {queue}
                    </Text>
                </Td>

                <Td style={cellStyle}>
                    <Badge
                        bg={status === "Y" ? "green.400" : bgStatus}
                        color={status === "Y" ? "white" : colorStatus}
                        fontSize="12px"
                        p="2px 6px"
                        borderRadius="8px">
                        {status === "Y" ? "सक्रिय" : "निष्क्रिय"}
                    </Badge>
                </Td>

                <Td style={cellStyle}>
                    <ButtonGroup size="sm" variant="ghost" spacing="2">
                        <Tooltip label="Edit" aria-label="Edit Tooltip">
                            <Button onClick={handleEditOpen}>Edit</Button>
                        </Tooltip>
                        <Tooltip label="Delete" aria-label="Delete Tooltip">
                            <Button colorScheme="red" onClick={handleDeleteOpen}>
                                Delete
                            </Button>
                        </Tooltip>
                        <Tooltip label="Change Time" aria-label="Change Time Tooltip">
                            <Button colorScheme="blue" onClick={handleChangeTimeOpen}>
                                Change Time
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Td>
            </Tr>

            {/* Edit Modal */}
            <Modal isOpen={isEditOpen} onClose={handleEditClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Bell Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input defaultValue={name} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Email</FormLabel>
                            <Input defaultValue={email} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Status</FormLabel>
                            <Select defaultValue={status}>
                                <option value="Y">Active</option>
                                <option value="N">Inactive</option>
                            </Select>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleEditClose}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={handleEditClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Change Time Modal */}
            <Modal isOpen={isChangeTimeOpen} onClose={handleChangeTimeClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Change Bell Time</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Hour</FormLabel>
                            <NumberInput defaultValue={parseInt(hourTime.split(":")[0])} min={0} max={23}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Minute</FormLabel>
                            <NumberInput defaultValue={parseInt(hourTime.split(":")[1])} min={0} max={59}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleChangeTimeClose}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={handleChangeTimeClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={isDeleteOpen} onClose={handleDeleteClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Delete</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this bell record?
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleDeleteClose}>
                            Delete
                        </Button>
                        <Button variant="ghost" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default BelllTableRow;