import {Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {useSpeechSynthesis} from 'react-speech-kit';
import AnnouncementForm from "./Announcements/Announcements";
import BellTable from "./Home/BellQueue";

const TextToSpeech = () => {
    const {speak} = useSpeechSynthesis();

    const handleClick = () => {
        speak({text: 'Hello, world!', lang: 'en-US'});
    };

    return <button onClick={handleClick}>Speak</button>;
};

const ControlTab = () => {

    return (
        <Box p={4}>
            <Tabs variant="enclosed" colorScheme="teal">
                <TabList>
                    <Tab>Home</Tab>
                    <Tab>Announcements</Tab>
                    <Tab>Schedules</Tab>
                    <Tab>Sound Library</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {/*<Heading size="md">Home</Heading>*/}
                        <BellTable/>
                        {/*<Example/>*/}
                        {/*<Text>Overview of recent announcements, reminders, and today's schedule.</Text>*/}
                    </TabPanel>

                    <TabPanel>
                        <Heading size="md">Announcements</Heading>
                        <AnnouncementForm/>
                        {/*<Text>Create, manage, and schedule announcements for specific events.</Text>*/}
                    </TabPanel>

                    <TabPanel>
                        <Heading size="md">Schedules</Heading>
                        {/*<Text>Configure daily, weekly, and custom bell schedules with ease.</Text>*/}
                    </TabPanel>

                    <TabPanel>
                        <Heading size="md">Sound Library</Heading>
                        {/*<Text>Manage and preview sound files used for announcements and bells.</Text>*/}
                    </TabPanel>

                </TabPanels>
            </Tabs>
        </Box>
    );
}

export default ControlTab;
