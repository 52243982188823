import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Stack,
    Switch,
} from "@chakra-ui/react";
import {AddIcon, DeleteIcon} from "@chakra-ui/icons";
import React, {useState} from "react";
import {fileUpload} from "../../../../Services/FileService";

function AddBellModal({isOpen, onClose, handleBellUpload}) {
    const [bellList, setBellList] = useState([
        {
            id: Date.now(),
            name: "",
            type: "BELL", // Default type
            time: "",
            music: null,
            status: false,
        },
    ]);

    const handleInputChange = (id, e) => {
        const {name, value} = e.target;
        setBellList((prev) =>
            prev.map((bell) =>
                bell.id === id ? {...bell, [name]: value} : bell
            )
        );
    };

    const handleTypeChange = (id, value) => {
        setBellList((prev) =>
            prev.map((bell) =>
                bell.id === id ? {...bell, type: value} : bell
            )
        );
    };

    const uploadBellFile = async (file) => {
        const fileUrl = await fileUpload(file);
        return fileUrl;
    }

    const handleFileChange = async (id, e) => {

        const fileUrl = await uploadBellFile(e.target.files[0]);
        console.log("lag::", fileUrl)
        setBellList((prev) =>
            prev.map((bell) =>
                bell.id === id
                    ? {
                        ...bell,
                        music: fileUrl,
                    }
                    : bell
            )
        );
    };

    const handleStatusChange = (id) => {
        setBellList((prev) =>
            prev.map((bell) =>
                bell.id === id ? {...bell, status: !bell.status} : bell
            )
        );
    };

    const addNewBell = () => {
        setBellList((prev) => [
            ...prev,
            {
                id: Date.now(),
                name: "",
                type: "BELL",
                time: "",
                music: null,
                status: false,
            },
        ]);
    };

    const removeBell = (id) => {
        setBellList((prev) => prev.filter((bell) => bell.id !== id));
    };

    const handleSubmit = () => {
        console.log("Bell List Submitted:", bellList);
        handleBellUpload(bellList);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add Multiple Bells</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Box maxH="400px" overflowY="auto">
                        {bellList.map((bell, index) => (
                            <Box
                                key={bell.id}
                                p="4"
                                mb="4"
                                borderWidth="1px"
                                borderRadius="lg"
                                position="relative"
                            >
                                <FormControl mb="4">
                                    <FormLabel>Bell Name</FormLabel>
                                    <Input
                                        name="name"
                                        value={bell.name}
                                        onChange={(e) =>
                                            handleInputChange(bell.id, e)
                                        }
                                        placeholder="Enter bell name"
                                    />
                                </FormControl>

                                <FormControl mb="4">
                                    <FormLabel>Bell Type</FormLabel>
                                    <RadioGroup
                                        onChange={(value) =>
                                            handleTypeChange(bell.id, value)
                                        }
                                        value={bell.type}
                                    >
                                        <Stack direction="row">
                                            <Radio value="BELL">BELL</Radio>
                                            <Radio value="NATIONA_ANTHEM">
                                                National Anthem
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </FormControl>

                                <FormControl mb="4">
                                    <FormLabel>Select Time</FormLabel>
                                    <Input
                                        type="time"
                                        name="time"
                                        value={bell.time}
                                        onChange={(e) =>
                                            handleInputChange(bell.id, e)
                                        }
                                    />
                                </FormControl>

                                <FormControl mb="4">
                                    <FormLabel>Upload Bell Music</FormLabel>
                                    <Input
                                        type="file"
                                        accept="audio/*"
                                        onChange={(e) =>
                                            handleFileChange(bell.id, e)
                                        }
                                    />
                                </FormControl>

                                <FormControl
                                    display="flex"
                                    alignItems="center"
                                    mb="4"
                                >
                                    <FormLabel>Status</FormLabel>
                                    <Switch
                                        isChecked={bell.status}
                                        onChange={() =>
                                            handleStatusChange(bell.id)
                                        }
                                    />
                                </FormControl>

                                {bellList.length > 1 && (
                                    <IconButton
                                        icon={<DeleteIcon/>}
                                        size="sm"
                                        position="absolute"
                                        top="4"
                                        right="4"
                                        colorScheme="red"
                                        onClick={() => removeBell(bell.id)}
                                    />
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Button
                        leftIcon={<AddIcon/>}
                        onClick={addNewBell}
                        colorScheme="blue"
                        mt="4"
                    >
                        Add Bell
                    </Button>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr="3" onClick={handleSubmit}>
                        Save All
                    </Button>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AddBellModal;