import Axios from "./axios.js";
import {fileApiConstants} from "./constants/ApiConstant.js";

export async function uploadFile(params) {
    return Axios.post(fileApiConstants.UPLOAD_FILE,params);
}

export async function uploadFileList(params) {
    return Axios.post(fileApiConstants.UPLOAD_FILES,params);
}


