import { useState, useEffect } from 'react';

const Clock = () => {
    const [time, setTime] = useState("");

    useEffect(() => {
        // Function to format the current time as HH:mm AM/PM
        const updateTime = () => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const formattedTime = `${("0" + (hours % 12 || 12)).slice(-2)}:${("0" + minutes).slice(-2)} ${hours >= 12 ? "PM" : "AM"}`;
            setTime(formattedTime);
        };

        // Initial call to set the time immediately
        updateTime();

        // Set interval to update time every minute
        const intervalId = setInterval(updateTime, 60000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return <div>{time}</div>;
};

export default Clock;
