import {Box, HStack, Icon, IconButton, Text, Tooltip, useToast} from '@chakra-ui/react';
import {useEffect, useRef, useState} from "react";
import Clock from "./Clock";
import {playEmergencyBell, toggleBellSwitch} from "../../views/Dashboard/services";
import {FaCircle, FaFireAlt, FaMountain} from "react-icons/fa"; // Fire and Earthquake alternatives
import {FiPower} from 'react-icons/fi'; // General icons
import {BiAlarmExclamation} from "react-icons/bi";
import useWebSocketContext from "../../socket/SchedulerComponent"; // Other Emergency alternative


const RemoteScreen = ({bellSwitch, remoteEmergency, messages}) => {
    const messagesEndRef = useRef(null);

    const payload = useWebSocketContext();
    console.log("RemoteScreen payload from bell server:", payload);

    // Scroll to the bottom whenever messages update
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    }, [messages]);

    return (
        <>
            <Text>Digital Bell System</Text>
            <Box
                mt={2}
                p={2}
                maxHeight="200px"
                overflowY="auto"
                bg="gray.800"
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.600"
                fontFamily="monospace"
            >
                <HStack spacing={3} align="center">
                    <Box
                        display="flex"
                        alignItems="center"
                        color={bellSwitch ? "green.500" : "red.500"}
                    >
                        <Icon as={FaCircle} boxSize={3} mr={2}/>
                        <Text fontSize="lg" fontWeight="bold">
                            {bellSwitch ? "घण्टी खुला छ" : "घण्टी बन्द छ"}
                        </Text>
                    </Box>
                </HStack>
            </Box>
            <Text fontSize="2xl" color="yellow.300" mt={2}><Clock/></Text>
            <Text fontSize="sm" color="gray.400">अर्को घण्टी: 12:00 PM</Text>
            <Text fontSize="sm" color={bellSwitch ? "green.300" : "red.400"}>
                Status: {bellSwitch ? "Active" : "Inactive"}
            </Text>

            {remoteEmergency && (
                <Text fontSize="sm" color="red.400">Emergency Mode Activated</Text>
            )}
        </>
    );
};

const DigitalBellRemote = () => {
    const [bellSwitch, setBellSwitch] = useState(Boolean(localStorage.getItem("isOn")));
    const [remoteEmergency, setRemoteEmergency] = useState(false);
    const toast = useToast();

    const handleBellSwitchChange = () => {
        const toggle = !bellSwitch;
        setBellSwitch(toggle);
        localStorage.setItem("isOn", toggle);

        try {
            const request = {isEnabled: bellSwitch};
            toggleBellSwitch(request);
        } catch (e) {
            console.error("Error ::", e);
        }
        toast({
            title: bellSwitch ? "Bell turned OFF" : "Bell turned ON",
            status: bellSwitch ? "warning" : "success",
            duration: 2000,
            isClosable: true,
        });
    };

    const handleEmergencyButton = async (type) => {
        const message =
            type === "fire"
                ? "Fire Emergency Activated"
                : type === "earthquake"
                    ? "Earthquake Emergency Activated"
                    : "Other Emergency Mode Activated";

        setRemoteEmergency(!remoteEmergency);
        toast({
            title: message,
            description: remoteEmergency ? `${message} Deactivated` : `${message} Activated`,
            status: "error",
            duration: 2000,
            isClosable: true,
        });

        try {
            let reuqestDto = {
                emergencyType: type === "fire" ? "FIRE" : type === "earthquake" ? "EARTHQUAKE" : "EMERGENCY"
            }
            const response = await playEmergencyBell(reuqestDto);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Box
            p={5}
            maxW="lg"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg="gray.900"
            color="white"
        >
            <Box
                mb={4}
                p={3}
                borderWidth="2px"
                borderRadius="md"
                borderColor="gray.600"
                bg="gray.700"
                color="teal.200"
                textAlign="center"
                fontSize="lg"
                fontFamily="monospace"
                position="relative"
            >
                <RemoteScreen
                    bellSwitch={bellSwitch}
                    remoteEmergency={remoteEmergency}
                />

                <IconButton
                    aria-label={bellSwitch ? "Turn OFF" : "Turn ON"}
                    icon={<FiPower/>}
                    colorScheme={bellSwitch ? "red" : "green"}
                    size="md"
                    borderRadius="full"
                    position="absolute"
                    top="2"
                    right="2"
                    onClick={handleBellSwitchChange}
                />
            </Box>

            {/* Additional Emergency Buttons */}
            <HStack spacing={4} justify="center" mt={4}>
                <Tooltip label="Fire Emergency" fontSize="md">
                    <IconButton
                        aria-label="Fire Emergency"
                        icon={<FaFireAlt/>}
                        colorScheme="orange"
                        size="lg"
                        onClick={() => handleEmergencyButton("fire")}
                    />
                </Tooltip>
                <Tooltip label="Earthquake Emergency" fontSize="md">
                    <IconButton
                        aria-label="Earthquake Emergency"
                        icon={<FaMountain/>}
                        colorScheme="blue"
                        size="lg"
                        onClick={() => handleEmergencyButton("earthquake")}
                    />
                </Tooltip>
                <Tooltip label="Other Emergency" fontSize="md">
                    <IconButton
                        aria-label="Other Emergency"
                        icon={<BiAlarmExclamation/>}
                        colorScheme="yellow"
                        size="lg"
                        onClick={() => handleEmergencyButton("other")}
                    />
                </Tooltip>
            </HStack>

        </Box>
    );
};

export default DigitalBellRemote;