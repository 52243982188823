// Chakra imports
import {Flex} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {RosterTable} from "./RosterTable";
import {searchRoster} from "./services";
import Bell from "./Bells/Bell";
import Card from "../../../components/Card/Card";

function Tables() {

    const [rosterList, setRosterList] = useState([])
    const [isRosterPage, setIsRosterPage] = useState(true)

    useEffect(() => {
        fetchRosterList()
    }, []);

    const fetchRosterList = async () => {
        try {
            let requestDTO = {
                code: "",
                startDate: "",
                endDate: "",
            }
            const response = await searchRoster(requestDTO)
            if (response.status === 200) {
                setRosterList(response.data)
            }
        } catch (e) {
            console.log("Error while fetching roster list e:", e);
        }
    }
    return (
        <Flex direction='column' pt={{base: "120px", md: "75px"}}>
            {isRosterPage ?
                <RosterTable
                    setIsRosterPage={setIsRosterPage}
                    rosterList={rosterList}
                    title={"Period Roster"}
                    description={"Architects design houses"}/>
                :
                <Bell/>
            }
        </Flex>
    );
}

export default Tables;
