import { useState } from 'react';
import { Td, Flex, Avatar, Text, Button, Icon } from '@chakra-ui/react';
import { FaPlay, FaStop } from 'react-icons/fa';
import musicLogo from "assets/icons/music.png"

function BellPlayer({ bell, audioSrc, music, textColor }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audio = new Audio(audioSrc);

    const handlePlay = () => {
        audio.play();
        setIsPlaying(true);
        audio.addEventListener('ended', () => setIsPlaying(false));
    };

    const handleStop = () => {
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(false);
    };

    return (
        <Td minWidth={{ sm: "20px" }} pl="0px">
            <Flex align="center" py=".8rem" minWidth="50%" flexWrap="nowrap">
                <Avatar src={musicLogo} w="50px" borderRadius="12px" me="18px" />
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold" minWidth="100%">
                        {bell}
                    </Text>
                    {/*<Button*/}
                    {/*    onClick={isPlaying ? handleStop : handlePlay}*/}
                    {/*    colorScheme={isPlaying ? 'red' : 'green'}*/}
                    {/*    leftIcon={<Icon as={isPlaying ? FaStop : FaPlay} />}*/}
                    {/*    mt="0.5rem"*/}
                    {/*    size="sm"*/}
                    {/*>*/}
                    {/*    {isPlaying ? 'Stop' : 'Play'}*/}
                    {/*</Button>*/}
                </Flex>
            </Flex>
        </Td>
    );
}

export default BellPlayer;