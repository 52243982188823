// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import Logout from "views/Auth/Logout/Logout.js";

import {
    CreditIcon,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    RocketIcon,
    StatsIcon,
    SupportIcon,
} from "components/Icons/Icons";
import Roster from "./views/Dashboard/Roster";

var dashRoutes = [
    {
        path: "/dashboard",
        name: "ड्यासबोर्ड",
        rtlName: "لوحة القيادة",
        icon: <HomeIcon color="inherit"/>,
        component: Dashboard,
        layout: "/admin",
    },
    {
        path: "/roster",
        name: "अवधि सूची",
        rtlName: "لوحة القيادة",
        icon: <StatsIcon color="inherit"/>,
        component: Roster,
        layout: "/admin",
    },
    {
        path: "/tables",
        name: "घण्टीहरू",
        rtlName: "لوحة القيادة",
        icon: <StatsIcon color="inherit"/>,
        component: Tables,
        layout: "/admin",
    },
    {
        path: "/tables",
        name: "Tables",
        rtlName: "لوحة القيادة",
        icon: <StatsIcon color="inherit"/>,
        component: Tables,
        layout: "/admin",
    },
    {
        path: "/billing",
        name: "Settings",
        rtlName: "لوحة القيادة",
        icon: <CreditIcon color="inherit"/>,
        component: Billing,
        layout: "/admin",
    },
    {
        name: "ACCOUNT",
        category: "account",
        rtlName: "صفحات",
        state: "pageCollapse",
        views: [
            // {
            //     path: "/profile",
            //     name: "Profile",
            //     rtlName: "لوحة القيادة",
            //     icon: <PersonIcon color="inherit"/>,
            //     secondaryNavbar: true,
            //     component: Profile,
            //     layout: "/admin",
            // },
            // {
            //     path: "/signin",
            //     name: "Sign In",
            //     rtlName: "لوحة القيادة",
            //     icon: <DocumentIcon color="inherit"/>,
            //     component: SignIn,
            //     layout: "/auth",
            // },
            {
                path: "/logout",
                name: "साइन आउट",
                icon: <RocketIcon color="inherit"/>,
                component: Logout,
                layout: "/auth",
            },
            // {
            //     path: "/signup",
            //     name: "Sign Up",
            //     rtlName: "لوحة القيادة",
            //     icon: <RocketIcon color="inherit"/>,
            //     secondaryNavbar: true,
            //     component: SignUp,
            //     layout: "/auth",
            // },
        ],
    },
];
export default dashRoutes;
