import React, {useState} from "react";
import {
    Badge,
    Button,
    Editable,
    EditableInput,
    EditablePreview,
    Flex,
    Icon,
    Input,
    Progress,
    Td,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import {FaEllipsisV} from "react-icons/fa";
import {FiBell} from "react-icons/fi";

function TablesProjectRow(props) {
    const {
        logo,
        name: initialName,
        status: initialStatus,
        hourTime: initialHourTime,
        queue: initialQueue,
        progression: initialProgression
    } = props;
    const textColor = useColorModeValue("gray.700", "white");

    // State to handle editable fields
    const [name, setName] = useState(initialName);
    const [status, setStatus] = useState(initialStatus);
    const [hourTime, setHourTime] = useState(initialHourTime);
    const [queue, setQueue] = useState(initialQueue);
    const [progression, setProgression] = useState(initialProgression);

    return (
        <Tr>
            <Td minWidth={{sm: "250px"}} pl="0px">
                <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Icon as={FiBell} boxSize={6} color="red.500"/>
                    
                    <Editable
                        defaultValue={name}
                        onSubmit={(value) => setName(value)}
                        minWidth="100%"
                    >
                        <EditablePreview fontSize="md" color={textColor} fontWeight="bold"/>
                        <EditableInput/>
                    </Editable>
                </Flex>
            </Td>
            <Td>
                <Editable
                    defaultValue={hourTime}
                    onSubmit={(value) => setHourTime(value)}
                >
                    <EditablePreview fontSize="md" color={textColor} fontWeight="bold"/>
                    <EditableInput/>
                </Editable>
            </Td>
            <Td>
                <Editable
                    defaultValue={queue}
                    onSubmit={(value) => setQueue(value)}
                >
                    <EditablePreview fontSize="md" color={textColor} fontWeight="bold"/>
                    <EditableInput/>
                </Editable>
            </Td>
            <Td>
                {status === 'Y' ?
                    <Badge variant="solid" bgColor="green">सक्रिय</Badge>
                    :
                    <Badge variant="solid" bgColor="blue">
                        निष्क्रिय
                    </Badge>}
            </Td>
            <Td>
                <Button p="0px" bg="transparent">
                    <Icon as={FaEllipsisV} color="gray.400" cursor="pointer"/>
                </Button>
            </Td>
        </Tr>
    );
}

export default TablesProjectRow;