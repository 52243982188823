import {uploadFile, uploadFileList} from "../common-services.js";

export const fileUpload = async (file) => {
    console.log("inside file upload");

    let formData = new FormData();
    formData.append("file", file);
    try {
        const response = await uploadFile(formData);
        console.log("File uploaded successfully", response.data)
        return response.data;
    } catch (e) {
        console.log("Error :{}", e)
    }

}

export const fileUploads = async (files) => {

    let formData = new FormData();
    // Append each file to formData with the correct key
    files && files.forEach((file, index) => {
        formData.append('files[]', file);  // Add the square brackets to match the backend
    });
    try {
        const response = await uploadFileList(formData);
        console.log("File uploaded successfully", response.data)
        return response.data;
    } catch (e) {
        console.log("Error :{}", e)
    }

}