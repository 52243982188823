import React, {useState} from "react";
// Chakra imports
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/bell.png";
import {signInUser} from "./services";

function SignIn() {

    const [email, setEmail] = useState({})
    const [password, setPasseword] = useState({})

    const [loginError, setLoginError] = useState(false);

    const signIn = async () => {

        let requestDto = {
            email: email,
            password: password
        }

        await signInUser(requestDto)
            .then(async (response) => {
                if (response.status === 200) {
                    const {data} = response;
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("username", data.username);
                    await localStorage.setItem("userInfo", JSON.stringify(data?.userInfo));
                    window.location.href = "/#/dashboard";
                } else {
                    setLoginError(true);
                }
            })
            .catch((e) => {
                console.log("error :::" + e);
                setLoginError(true);
            });

    }
    // Chakra color mode
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <Flex position='relative' mb='40px'>
            <Flex
                h={{sm: "initial", md: "75vh", lg: "85vh"}}
                w='100%'
                maxW='1044px'
                mx='auto'
                justifyContent='space-between'
                mb='30px'
                pt={{sm: "100px", md: "0px"}}>
                <Flex
                    alignItems='center'
                    justifyContent='start'
                    style={{userSelect: "none"}}
                    w={{base: "100%", md: "50%", lg: "42%"}}>
                    <Flex
                        direction='column'
                        w='100%'
                        background='transparent'
                        p='48px'
                        mt={{md: "150px", lg: "80px"}}>
                        <Heading color={titleColor} fontSize='32px' mb='10px'>
                            डिजिटल घण्टी प्रणालीमा स्वागत छ
                        </Heading>
                        <Text
                            mb='36px'
                            ms='4px'
                            color={textColor}
                            fontWeight='bold'
                            fontSize='14px'>
                            फेरि स्वागत छ लग इन गर्न
                        </Text>
                        <FormControl>
                            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                                इमेल
                            </FormLabel>
                            <Input
                                borderRadius='15px'
                                mb='24px'
                                fontSize='sm'
                                type='text'
                                placeholder='Your email adress'
                                size='lg'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                                पासवर्ड
                            </FormLabel>
                            <Input
                                borderRadius='15px'
                                mb='36px'
                                fontSize='sm'
                                type='password'
                                placeholder='Your password'
                                size='lg'
                                onChange={(e) => setPasseword(e.target.value)}
                            />
                            <FormControl display='flex' alignItems='center'>
                                <Switch id='remember-login' colorScheme='teal' me='10px'/>
                                <FormLabel
                                    htmlFor='remember-login'
                                    mb='0'
                                    ms='1'
                                    fontWeight='normal'>
                                    Remember me
                                </FormLabel>
                            </FormControl>

                            {loginError === true ? (
                                <>
                                    <p className="text-red-500 text-sm mt-[6px]">
                                        प्रयोगकर्ताको नाम वा पासवर्ड मेल खाँदैन।
                                    </p>
                                    <br></br>
                                </>
                            ) : (
                                ""
                            )}

                            <Button
                                fontSize='10px'
                                type='submit'
                                bg='teal.300'
                                w='100%'
                                h='45'
                                mb='20px'
                                color='white'
                                mt='20px'
                                _hover={{
                                    bg: "teal.200",
                                }}
                                _active={{
                                    bg: "teal.400",
                                }}
                                onClick={() => signIn()}
                            >
                                साइन इन
                            </Button>
                        </FormControl>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='0px'>
                            <Text color={textColor} fontWeight='medium'>
                                Don't have an account?
                                <Link color={titleColor} as='span' ms='5px' fontWeight='bold'>
                                    Sign Up
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Box
                    display={{base: "none", md: "block"}}
                    overflowX='hidden'
                    h='100%'
                    w='40vw'
                    position='absolute'
                    right='0px'>
                    <Box
                        bgImage={signInImage}
                        w='100%'
                        h='100%'
                        bgSize='cover'
                        bgPosition='50%'
                        position='absolute'
                        borderBottomLeftRadius='20px'></Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default SignIn;
