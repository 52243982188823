import Axios from "../../../../axios";
import {bellConstants} from "../../../../constants/ApiConstant";

export async function saveBell(params) {
    return Axios.post(bellConstants.BELL_SAVE, params);
}

export async function searchBell(params) {
    return Axios.post(bellConstants.BELL_SEARCH, params);
}

