import React, {useEffect, useState} from "react";
import BellTable from "./BellTable";
import {saveBell, searchBell} from "./services";


const mapToDTO = (list) => {
    return list.map((bell) => {
        const {name, time, music,type, status} = bell;

        // Extract hourTime and file details
        const hourTime = time; // Use the time directly as hourTime
        // const fileType = music ? music?.type : null;
        const fileUrl = music ? music : null;
        const enabled = status;

        return {
            name,
            hourTime,
            type,
            enabled,
            // fileType,
            fileUrl
        };
    });
}

function Bell() {
    const [bellList, setBellList] = useState([])

    useEffect(() => {
        fetchBellList()
    }, []);

    const handleBellUpload = async (data) => {
        console.log("data::", data)
        try {

            const dtoList = mapToDTO(data);
            let requestDto = {
                rosterId: 1,
                bellList: dtoList
            };
            const response = await saveBell(requestDto)

            if (response.status === 201) {
                fetchBellList();
            }
        } catch (e) {
            console.log(e)
        }

    }

    const fetchBellList = async () => {
        try {
            let requestDTO = {
                number: "",
                code: ""
            }
            const response = await searchBell(requestDTO)
            console.log("respinse", response)
            if (response.status === 200) {
                setBellList(response.data);
            }
        } catch (e) {
            console.error("Error while fetchBellList :{}", e)
        }
    }
    return (

        <BellTable
            title={"घन्टिहरु"}
            captions={["नाम", "समय स्थिति", "घण्टी", "पंक्ति", "स्थिति", "क्रियाकलाप"]}
            bellList={bellList}
            handleBellUpload={handleBellUpload}
        />
    );
}

export default Bell;
